import React from 'react';
import'../../styles/components/layout/Nav.css';
import { NavLink } from 'react-router-dom';

const Nav = (props) => {
    return (
        <nav>
            <div className="holder">
                <ul>
                    <li><NavLink activeClassName='active' end to='/'>Inicio</NavLink></li>
                    <li><NavLink activeClassName='active' to='/nosotros'>Nosotros</NavLink></li>
                    <li><NavLink activeClassName='active' to='/novedades'>Novedades</NavLink></li>
                    <li><NavLink activeClassName='active' to='/contacto'>Contacto</NavLink></li>
                </ul>
            </div>
        </nav>
    )
}
export default Nav;


