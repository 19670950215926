import React from 'react';
import '../styles/components/pages/HomePage.css';

const HomePage = (props) => {
    return (
        <div className="holder">
            <div className='encabezado'>
                <div className="homeimg">
                    <img src="images/home/png(13).png" alt="inicio"  style={{borderRadius: '15px', width:'70%'}} />
                </div>
                <div className='login'>
                    <h3>Admin</h3>
                    <a class="btn btn-primary" href="https://misdevback.juanromero.ar" role="button">Login</a>
                </div>
            </div>
            <div className="columnas">
                <div className="bienvenidos">
                    <h2>Bienvenidos</h2>
                    <p><>Somos una startup de desarrollo de sitios webs hibridos que acerca las nuevas tendencias en diseño de apps y web a cualquier sector. Nuestra misión es resolver las necesidades de nuestros clientes apoyándonos en las nuevas tecnologías para adaptarlas a su entorno y potenciar su transformación digital. Nuestros esfuerzos van dirigidos a crear soluciones concretas para problemas específicos, partiendo siempre de metodologías ágiles de desarrollo.</></p>
                </div>
                <div className="testimonios">
                    <h2>Testimonios</h2>
                    <div className="testimonio">
                        <span class="cita">Excelente servicios, entregaron la aplicación en tiempo y forma.</span>
                        <span class="autor">Lucas Dávalos</span>
                    </div>
                    <br/>
                    <div className="testimonio">
                        <span class="cita">Hemos incrementado las ventas gracias a su app, funciona según los esperado.</span>
                        <span class="autor">Romina Pasante</span>
                    </div>
                </div>    
            </div>
        </div>
    )
}

export default HomePage;