import React from 'react';
import '../styles/components/pages/ContactoPage.css'
import { useState } from 'react';
import axios from 'axios';
  
const ContactoPage = (props) => {
    const initialForm = {
        nombre: '',
        email: '',
        telefono: '',
        mensaje: ''    
    }

    const [sending, setSending] = useState(false);
    const [msg, setMsg] = useState('');
    const [formData, setFormData] = useState(initialForm);


    const handeChange = e =>{
        const {name, value} = e.target;
        setFormData(oldData=>({
            ...oldData,
            [name]: value
        }));
    }

    const handleSubmit = async e => {
        e.preventDefault();
        setMsg('')
        setSending(true)
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/contacto`, formData);
        setSending(false);
        setMsg(response.data.menssage);
        if(response.data.error === false){
            setFormData(initialForm)
        }
    }

    return (
        <div className="contacto">
            <div className="form">
                <h2>Contacto</h2>
                <form action="/contacto" method="post" className="formulario" onSubmit={handleSubmit}>
                    <p>
                        <input type="text" name="nombre" placeholder='Nombre' value={formData.nombre} onChange={handeChange} />
                    </p>
                    <p>
                        <input type="text" name="email" placeholder='Email' value={formData.email} onChange={handeChange}/>
                    </p>
                    <p>
                        <input type="text" name="telefono" placeholder='Telefono'  value={formData.telefono} onChange={handeChange}/>
                    </p>
                    <p>
                        <textarea name="mensaje" placeholder='Mensaje'v alue={formData.mensaje} onChange={handeChange}></textarea>
                    </p>
                    <p>
                        <input type="submit" value="Enviar" />
                    </p>
                </form>
                {sending ? <p>Enviando...</p> : null}
                {msg ? <p>{msg}</p> : null}
            </div>
            <div className="datos">
                <h2>Otras vías de comunicación</h2>
                <p>También se puede comunicar a través de:</p>
                    <ul>
                        <li>Teléfono: +543764606708</li>
                        <li>email: info@misdevs.com</li>
                        <li><a href='https://www.github.com/romeroresek'>GitHub</a></li>
                        <li><a href='https://www.linkedin.com/in/romeroresek/'>Linkedin</a></li>
                    </ul>
            </div>
        </div>
    )
}

export default ContactoPage;