import React from "react";

const NovedadItem = (props) =>{
    const {title, subtitle, imagen, body} = props;
    return (
        <div className="novedades">
            <h2 style={{'textAlign':'center'}}>{title}</h2>
            <h4>{subtitle}</h4>
            <img src={imagen} alt="" style={{'borderRadius':'5px', 'textAlign':'center'}}/>
            <br/>
            <div dangerouslySetInnerHTML={{ __html:body}} />
            <p><hr/></p>
        </div>
    )
}
export default NovedadItem;