import React from 'react';
import '../styles/components/pages/NosotrosPage.css'

const NosotrosPage = (props) => {
    return (
        <div className="holder">
            <div className="historia">
                    <h2>Una startup de software</h2>  
                    <p>Mis Devs es una empresa de software especializada en brindar servicios de software factory y transformación digital. Apoyamos a nuestros clientes a lo largo de todo el proceso hasta lograr los objetivos a los que nos comprometemos fuertemente. Adicionalmente incentivamos la transformación de las empresas mediante productos desarrollados a medida. Finalmente, lo que nos diferencia de otras consultoras de software es la agilidad y la idoneidad de nuestros equipos. Al afrontar un  desafío nos encanta resolverlo con CREATIVIDAD, CALIDAD y  con una FLEXIBILIDAD enfocada en el cliente, una respuesta rápida,  difícil de superar.</p>
                    <p>Contamos con un múltiples de alianzas que nos permiten asegurar el nivel de calidad final a nuestros clientes, tener una mejor capacidad de respuesta y ofrecer una óptima solución desde el punto de vista tanto del negocio  como ingenieril.</p>
            </div>
            <div className="staff">
                <h2>Equipo</h2>
                <div className="personas">
                <div className="persona">
                        <img src="images/nosotros/jj.jpg" alt="juan"/>
                        <h5>Juan Romero</h5>
                        <h6>Programador</h6>
                        <p>Conoce y es capaz de realizar las tareas de desarrollo Full Stack web con React.</p>
                    </div>
                    <div className="persona">
                        <img src="images/nosotros/angela1.jpg" alt="angela"/>
                        <h5>Angela Insaurralde</h5>
                        <h6>Programadora</h6>
                        <p>Se desempeña en el cargo de programadadora está preparada para enfrentar proyectos de diseño y desarrollo de software.</p>
                    </div>
                    <div className="persona">
                        <img src="images/nosotros/veronica1.jpg" alt="juan"/>
                        <h5>Verónica Rodriguez</h5>
                        <h6>Diseñador</h6>
                        <p>Experto en usabilidad de aplicaciones y sitios web.</p>
                    </div>
                    <div className="persona">
                        <img src="images/nosotros/carolina.jpg" alt="juan"/>
                        <h5>Carolina Montiel</h5>
                        <h6>Tester</h6>
                        <p>Se encargará de asegurar que los requisitos definidos por el arquitecto de software se cumplen en la implementación del producto o servicio realizada por los desarrolladores y/o programadores.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NosotrosPage;