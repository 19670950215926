import React from 'react';
import'../../styles/components/layout/Header.css';


const Header = (props) => {
    return (
        <header>
            <div className='logo'>
                <img src="images/logo.png" width="100" alt="Mi Negocio" />
                <h1>MisDev</h1>
            </div>
        </header>
    )
}
export default Header;