import React from 'react';
import'../../styles/components/layout/Footer.css';


const Footer = (props) => {
    return (
        <footer>
            <p>Startup de Desarrollo de Software y aplicaciones - 2022</p>
        </footer>    
    );
}

export default Footer;